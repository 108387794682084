<template>
    <modal ref="modalDuplicarImagen" titulo="Duplicar imagen en cedis" :cargado-modal="loading" no-aceptar adicional="Duplicar" @adicional="duplicarAnuncios">
        <div class="row mx-0 justify-center">
            <div class="col-8 text-center text-general f-15 mb-2 ">
                Selecciona los cedis a los cual se duplicará
            </div>
            <div class="col-8 text-general f-15 mb-2 ">
                Cedis
            </div>
            <div v-for="(data,d) in cedis" :key="d" class="col-8 px-4 mb-3 mt-2">
                <el-checkbox v-model="data.checked" class="check-red">
                    {{ data.nombre }}
                </el-checkbox>
            </div>
        </div>
    </modal>
</template>

<script>
import Publicidad from "~/services/publicidad/publicidad_admin"
export default {
    data(){
        return {
            loading: false,
            cedis: [],
            id_cedis: null,
            tipo: null,
        }
    },
    methods: {
        toggle({cedisValidos, id_cedis, tipo }){
            console.log({cedisValidos, id_cedis, tipo})
            this.cedis = _.cloneDeep(cedisValidos)
            this.id_cedis = id_cedis
            this.tipo = tipo
            this.$refs.modalDuplicarImagen.toggle();
        },
        async duplicarAnuncios(){
            try {

                let cedis = this.cedis.filter(d => d.checked).map(({id}) => id)
                let params = {
                    cedis,
                    id_cedis: this.id_cedis,
                    tipo: this.tipo
                }
                this.loading = true
                const {data} = await Publicidad.duplicarAnuncios(params)
                this.notificacion('', data.mensaje, 'success')
                this.$emit('duplicar')
                this.$refs.modalDuplicarImagen.toggle()
            } catch (e){
                this.error_catch(e)
            }finally{
                this.loading = false
            }
        },
    }
}
</script>

<style>

</style>
